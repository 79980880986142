.learning-platform {
    display: flex;
    background-color: black;
    color: white;
    height: 100vh;
    font-family: 'Sohne', sans-serif;
  }
  
  .left-content {
    width: 50%;
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title-line {
    width: 40px;
    height: 4px;
    background-color: #4FD1C5;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .feature-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
  }
  
  .feature-list li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
    text-align: left;

  }
  
  .feature-list li::before {
    content: "•";
    color: #ffffff;
    position: absolute;
    left: 0;
    text-align: left;

  }
  
  .highlight {
    color: #fff587;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;

  }
  
  .conclusion {
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
    text-align: left;

  }
  
    
  .headline-text {
    font-size: 48px;
    font-weight: bold;
    font-family: 'Sohne', sans-serif;
  
    line-height: 1.2;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: left;
  }
  
  .paragraph-text {
    font-size: 20px;
    font-family: 'Sohne', sans-serif;
  
    line-height: 1.5;
    margin-bottom: 30px;
    color: #ffffff;
    text-align: left;
  }
  
  .button-signup {
    background-color: #e91e63;
    color: white;
    padding: 15px 0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Sohne', sans-serif;
    transition: background-color 0.3s;
    width: 100%;
    max-width: 300px;
  }
  
  .button-signup:hover {
    background-color: #d81b60;
  }
  
  .right-content {
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .image-grid {
    position: relative;
    width: 700px;
    animation: slide-up 40s linear infinite;
    
  }
  
  .image-pair {
    position: relative;
    display: flex;
  }
  
  .left-image {
    width: 50%;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  
  }
  
  .right-image {
    width: 50%;
    position: absolute;
    left: 50%;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .left-image img,
  .right-image img {
    width: 100%;
    display: block;
  }
  
  .fade-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, black 0%, transparent 10%, transparent 90%, black 100%);
    pointer-events: none;
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  
  @media (max-width: 768px) {
    .learning-platform {
      flex-direction: column;
      height: auto;
      overflow: hidden;
    }
  
    .left-content {
      order: 2;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  
    .right-content {
      order: 1;
      width: 100%;
      height: 30vh;
      overflow: hidden;
    }
  
    .title {
      font-size: 22px;
    }
  
    .description, .feature-list li, .conclusion {
      font-size: 14px;
      line-height: 1.4;
    }
  
    .highlight {
      font-size: 16px;
    }
  
    .feature-list {
      padding-left: 20px;
    }
  
    .feature-list li {
      margin-bottom: 10px;
    }
  
    .image-grid {
      width: 100%;
      height: 100%;
    }
  
    .image-pair {
      flex: 0 0 100%;
      height: 50%;
    }
  
    .image-wrapper {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      width: 50% !important;
      height: 100% !important;
      float: left;
    }
  
    .image-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  @media (max-width: 375px) {
    .right-content {
      height: 25vh;
    }
  
    .title {
      font-size: 20px;
    }
  
    .description, .feature-list li, .conclusion {
      font-size: 12px;
    }
  
    .highlight {
      font-size: 14px;
    }
  }