.member-testimonials {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 80px 20px;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.member-testimonials h2 {
  font-size: 2.5em;
  margin-bottom: 50px;
  color: #fff;
}

.testimonial-slider {
  max-width: 1200px;
  width: 100%;
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-card {
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  position: relative;
}

.testimonial-content {
  flex: 1;
  text-align: left;
  padding-left: 180px; /* Adjust this value based on the avatar size */
}

.avatar-container {
  position: absolute;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.avatar {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.comment {
  font-style: italic;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.name {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.role {
  color: #ccc;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.nav-button svg {
  color: #000;
}

.prev-button {
  left: -60px;
}

.next-button {
  right: -60px;
}

@media (max-width: 768px) {
  .member-testimonials {
    padding: 40px 10px;
  }

  .testimonial-card {
    flex-direction: column;
    align-items: center;
  }

  .avatar-container {
    margin-bottom: 20px;
  }

  .testimonial-content {
    text-align: center;
  }

  .nav-button {
    top: auto;
    bottom: 10px;
    transform: none;
  }
}
