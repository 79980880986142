.categories {
    background-color: black;
    color: white;
    padding: 24px;
    font-family: 'Sohne', sans-serif;
  }
  
  .categories-heading {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 60px;
    text-transform: uppercase;
    color: white;
  }
  
  .category-items {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }
  
  .category-item {
    padding: 12px 24px;
    border-radius: 9999px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s;
    background-color: #1F2937;
    color: #9CA3AF;
    border: 2px solid #374151;
    cursor: pointer;
  }
  
  .category-item.active {
    color: #FFC107;
    border-color: #FFC107;
  }
  
  .category-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .courses-container {
    position: relative;
    overflow: hidden;
    padding-left: 70px; /* İlk item'ın soldan 70px içeride olması için */
  }
  
  .courses {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 16px;
    transition: opacity 0.5s ease-in-out;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .courses::-webkit-scrollbar {
    display: none;
  }
  
  .course-card {
    width: 271px;
    height: 420px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    flex-shrink: 0; /* Kartların küçülmesini önlemek için */
  }
  
  .course-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%; /* Koyu alanı biraz daha yukarı çekmek için */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .course-card-content {
    position: absolute;
    bottom: 20px; /* İçeriği biraz daha yukarı taşımak için */
    left: 0;
    right: 0;
    padding: 16px;
    text-align: center;
    z-index: 1;
  }
  
  .course-card-content h3 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }  
  .course-card-divider {
    width: 40px;
    height: 2px;
    background-color: white;
    margin: 0 auto 8px;
  }
  
  .course-card-content p {
    margin: 0;
    color: #E5E7EB;
    font-size: 14px;
  }
  
  .arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .arrow-button.left {
    left: 80px; /* Sol ok butonunu da içeri almak için */
  }
  
  .arrow-button.right {
    right: 10px;
  }
  
  .fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }
  
  .fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @media (max-width: 768px) {
    .categories {
        padding: 24px 10px;
    }

    .categories-heading {
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .category-items {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        padding-bottom: 10px;
    }

    .category-item {
        flex-shrink: 0;
    }

    .courses-container {
        padding-left: 10px;
    }

    .arrow-button.left {
        left: 10px;
    }
}