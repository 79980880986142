.menu {
  background-color: #000000;
  padding: 10px 0;
  width: 100%;
  font-family: 'Sohne', sans-serif;
  position: relative;
  z-index: 1000; /* Yüksek bir z-index değeri ekleyelim */

}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.menu-logo {
  height: 40px;
}

.menu-center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.menu-list {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.menu-item {
  margin: 0;
}

.menu-item a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.menu-right {
  display: flex;
  align-items: center;
}

.apply-button {
  background-color: #F63939;
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 5px;
  text-transform: uppercase;
}

.social-icons {
  display: flex;
}

.social-icons a {
  color: white;
  margin-left: 10px;
  font-size: 20px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.menu-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .menu-container {
    flex-wrap: wrap;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .menu-content {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #000000;
    padding: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .menu-content.open {
    display: flex;
  }

  .menu-center, .menu-right {
    width: 100%;
  }

  .menu-list {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }

  .menu-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .apply-button {
    margin-right: 0;
    margin-bottom: 15px;
  }
}