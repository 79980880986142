.faq-container {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 80px 20px;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-container h2 {
  font-size: 2.5em;
  margin-bottom: 50px;
  text-align: center;
  color: #fff;
}

.faq-list {
  max-width: 1200px;
  width: 100%;
}

.faq-item {
  border-bottom: 1px solid #333;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 20px 0;
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-icon {
  font-size: 1.5em;
  color: #ffd700;
}

.faq-answer {
  padding: 0 0 20px;
  font-size: 1em;
  color: #ccc;
  line-height: 1.6;
}
