.slider-container {
    position: relative;
  }
  
  .acun-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9px;
    background-repeat: no-repeat;
    background-size: auto 9px;
    z-index: 10;
  }
  
  .slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 9px; /* Add padding to account for the acun-line */
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    background-size: cover;
    background-position: center;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width: 80%;
  }
  
  .slide-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .slide-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .slide-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .slide-button {
    padding: 0.8rem 1.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .slide-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  @media (max-width: 768px) {
    .slide-content h1 {
      font-size: 2rem;
    }
  
    .slide-content p {
      font-size: 1rem;
    }
  
    .slide-button {
      padding: 0.6rem 1rem;
    }
  }