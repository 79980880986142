.master-slider {
    position: relative;
    width: 100%;
    height: 655px;
    overflow: hidden;
  }
  
  .master-slider__slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .master-slider__slide--active {
    opacity: 1;
  }
  
  .master-slider__slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  }
  
  .master-slider__image,
  .master-slider__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .master-slider__content {
    position: absolute;
    bottom: 110px;
    left: 110px;
    max-width: 50%;
    color: white;
    text-align: left;
  }
  
  .master-slider__title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    text-align: left;
  }
  
  .master-slider__description {
    font-size: 16px;
    line-height: 1.5;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .master-slider__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: #FF8C00; /* Koyu turuncu */
    border: none;
    font-size: 48px;
    cursor: pointer;
    padding: 0;
  }
  
  .master-slider__arrow--left {
    left: 40px;
  }
  
  .master-slider__arrow--right {
    right: 40px;
  }
  
  .master-slider__dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .master-slider__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.5;
  }
  
  .master-slider__dot--active {
    opacity: 1;
  }