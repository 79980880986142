.acunmedya-form-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    flex-direction: row;
    overflow: hidden; /* Taşan içeriği gizle */
}

.form-acun-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* 100vw yerine 100% kullan */
    height: 9px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
}

.form-acun-line-bottom {
    position: absolute; /* fixed yerine absolute kullan */
    bottom: 0;
    left: 0;
    width: 100%; /* 100vw yerine 100% kullan */
    height: 9px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
}
.form-content {
    flex: 1;
    margin-right: 10px;
}

.form-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: black;
    text-align: left;
}

.form-basvuru-description {
    font-size: 0.9em;
    line-height: 1.4;
    margin-bottom: 30px;
    color: black;
}

.application-form {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.form-row input,
.form-row select {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 0.9em;
    background-color: transparent;
    outline: none;
}

.form-row input::placeholder,
.form-row select::placeholder {
    color: black;
}

.form-row input:last-child,
.form-row select:last-child {
    margin-right: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border-bottom: 1px solid black;
}

.form-checkbox {
    margin-bottom: 20px;
    font-size: 0.8em;
    color: black;
    text-align: left;
}

.form-checkbox label {
    display: block;
    margin-bottom: 10px;
}

input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
}

.submit-button {
    padding: 15px;
    background-color: #FF5733;
    color: white;
    border: none;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    max-width: 200px;
    align-self: flex-end;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #C43D22;
}

.form-image {
    flex: 0 0 40%;
    height: auto;
    border-radius: 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.form-image img {
    width: 100%;
    height: 700px;
    object-fit: cover;
    max-width: 100%;
}

@media (max-width: 768px) {
    .acunmedya-form-container {
        flex-direction: column;
        padding: 20px 10px;
    }

    .form-content {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .form-row {
        flex-direction: column;
    }

    .form-row input,
    .form-row select {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .form-image {
        display: none;
    }

    .submit-button {
        align-self: center;
        max-width: 100%;
    }

    .form-title {
        font-size: 24px;
    }

    .form-acun-line,
    .form-acun-line-bottom {
        width: 100%;
        left: 0;
        margin-left: 0;
    }
}