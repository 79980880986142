.educations-container {
    position: relative;
    padding: 20px 0;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .acun-line {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 9px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
  }
  
  h1 {
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-family: 'Sohne', sans-serif;

  }
  
  .description {
    margin-bottom: 20px;
    text-align: left;
    font-family: 'Sohne', sans-serif;

  }
  
  .educations-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  
  .educations-grid {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    transition: transform 0.3s ease;
  }
  
  .education-item {
    flex: 0 0 480px; /* Her kartın sabit genişliği */
    display: flex;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .education-content {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .education-content h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .education-content p {
    font-size: 0.9em;
    margin-bottom: 15px;
  }
  
  .education-image {
    width: 220px;
    height: 290px;
    overflow: hidden;
  }
  
  .education-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .incele-button {
    align-self: flex-start;
    display: inline-block;
    padding: 8px 15px;
    font-family: 'Sohne', sans-serif;

    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .incele-button:hover {
    background-color: #333;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #888;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
  }
  
  .slider-button.prev {
    left: 10px;
  }
  
  .slider-button.next {
    right: 10px;
  }
  
  @media (max-width: 768px) {
    .educations-container {
        padding: 20px 10px;
    }

    h1 {
        font-size: 24px;
    }

    .description {
        font-size: 14px;
    }

    .educations-grid {
        width: 100%;
    }

    .education-item {
        flex: 0 0 100%;
    }

    .education-image {
        width: 100%;
        height: 200px;
    }
}