.welcome-acun-container {
    position: relative;
  }
  
  .acun-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
  }
  
  .welcome-acun {
    width: 100%;
    padding: 50px 0;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    padding-top: 59px; /* 50px original padding + 9px for the line */
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .message {
    flex: 1;
    padding-right: 40px;
    color: white;
  }
  
  .message h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .message p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .image-container {
    flex: 0 0 40%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
  
  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column-reverse;
      padding: 0 20px;
    }
  
    .message {
      padding-right: 0;
      margin-top: 30px;
    }
  
    .image-container {
      margin-bottom: 30px;
    }
  }