.footer {
    background-color: #000000;
    color: #ffffff;
    padding: 40px 0 20px;
    font-family: 'Inter', sans-serif;
    position: relative;
}

.footer-content {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 69px;
    align-items: flex-start; /* Yeni eklendi */
}

.footer-left {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 40%;
}

.footer-top-line {
    width: 100%;
    height: 5px;
    background-color: #5FC4B8;
    margin-bottom: 40px;
}


.footer-right {
    width: 60%;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer-column {
    flex: 1;
    margin-right: 20px;
}

.footer-column:last-child {
    margin-right: 0;
}

.footer-logo {
    width: 137px;
    height: 137px;
    margin-right: 30px;
}

.footer-divider {
    width: 2px;
    height: 137px;
    background-color: #ffffff;
    margin: 0 30px;
    align-self: flex-start;
}

.footer-contact {
    font-size: 16px;
    line-height: 1.6;
    text-align: left;
}

.footer-contact p {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
}

.footer-contact svg {
    margin-right: 10px;
    color: #5FC4B8;
}

.footer-column h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: left;
}

.footer-divider-horizontal {
    width: 100%;
    height: 3px;
    background-color: #5FC4B8;
    margin-bottom: 20px;
}

.footer-column a {
    display: block;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
}

.footer-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 69px;
    margin-top: 80px;
    margin-bottom: 20px;
}

.footer-social a {
    color: #5FC4B8;
    font-size: 24px;
    margin-left: 20px;
    text-decoration: none;
}

.footer-bottom {
    max-width: 80%; /* Değiştirildi */
    margin: 0 auto;
    padding: 20px 0 0; /* Değiştirildi */
    border-top: 1px solid #ffffff; /* Değiştirildi */
    position: relative;
}

.footer-legal {
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-legal a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 15px;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .footer-content, .footer-social, .footer-bottom {
        padding: 0 20px;
    }

    .footer-content {
        flex-direction: column;
    }

    .footer-left, .footer-right {
        width: 100%;
    }

    .footer-links {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        padding: 0 20px;
    }

    .footer-left, .footer-right {
        width: 100%;
    }

    .footer-left {
        flex-direction: column;
        align-items: center;
    }

    .footer-logo {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .footer-divider {
        width: 100%;
        height: 2px;
        margin: 20px 0;
    }

    .footer-contact {
        text-align: center;
    }

    .footer-links {
        flex-direction: column;
    }

    .footer-column {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .footer-social {
        justify-content: center;
        padding: 0;
        margin-top: 40px;
    }

    .footer-bottom {
        max-width: 100%;
    }

    .footer-legal {
        flex-direction: column;
        align-items: center;
    }

    .footer-legal a {
        margin: 10px 0;
    }
}