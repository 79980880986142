.why-acunmedya-wrapper {
    background-color: #000; /* Arka planı siyah yap */
    padding: 40px 0; /* Componentin üst ve alt boşlukları */
    background-image: url('../images/acunbackground.png'); /* Arka plan resmi */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.why-acunmedya {
    color: #fff;
    padding: 40px;
    font-family: 'Sohne', sans-serif;
    max-width: 1200px; /* İçeriğin maksimum genişliğini belirler */
    margin: 0 auto; /* İçeriği ortalar */
    background-color: rgba(0, 0, 0, 0.8); /* İçeriğin arka planı */
    border-radius: 10px; /* Köşeleri yuvarlatır */
}

h1 {
    font-size: 36px;
    font-family: 'Sohne', sans-serif;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
}

.subtitle {
    font-size: 16px;
    font-family: 'Sohne', sans-serif;

    margin-bottom: 40px;
    text-align: center;
    color: #dcdcdc;
}

.content-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.column {
    width: 48%;
}

h2 {
    color: #ffd700;
    font-size: 24px;
    font-family: 'Sohne', sans-serif;

    margin-bottom: 20px;
    text-align: center;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
    color: #dcdcdc;
}

li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
}

.column:first-child li::before {
    background-image: url('../images/path_to_orange_icon.png');
}

.column:last-child li::before {
    background-image: url('../images/path_to_blue_icon.png');
}

strong {
    color: #add8e6;
}

@media (max-width: 768px) {
    .why-acunmedya {
        padding: 20px;
    }

    .content-container {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
    }

    .why-acunmedya-wrapper {
        padding: 20px 0;
    }

    li {
        padding-left: 30px;
    }
}