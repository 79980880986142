.moving-arrows {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.star {
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
  animation: twinkle-move 15s infinite linear;
}

@keyframes twinkle-move {
  0% {
    opacity: 0.2;
    transform: translate(0, 0) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translate(20px, 20px) scale(1);
  }
  100% {
    opacity: 0.2;
    transform: translate(40px, 40px) scale(0.8);
  }
}
