.contact-form-container {
    background-color: #000000;
    padding: 80px 0;
    font-family: 'Inter', sans-serif;
}

.contact-form {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    color: #ffffff;
}

.contact-form-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: left;
}

.contact-form-description {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 50px;
    max-width: 800px;
    text-align: left;
}

.contact-form em {
    font-style: italic;
}

.contact-form form {
    display: flex;
    gap: 50px;
}

.contact-form input {
    flex-grow: 1;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
}

.contact-form button {
    background-color: #F63939;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.contact-form button:hover {
    background-color: #F63939;
}

@media (max-width: 768px) {
    .contact-form-title {
        font-size: 36px;
    }

    .contact-form form {
        flex-direction: column;
    }

    .contact-form button {
        width: 100%;
    }
}