/* Söhne Buch (Normal) */
@font-face {
    font-family: 'Sohne';
    src: local('Söhne Buch'), local('Sohne-Buch'),
         url('./sohne-buch.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  /* Söhne Halbfett (Bold) */
  @font-face {
    font-family: 'Sohne';
    src: local('Söhne Halbfett'), local('Sohne-Halbfett'),
         url('./sohne-halbfett.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }